import { formatDate, useResolveMgoNSName } from '@mgonetwork/core';
import { Heading, Text } from '@mgonetwork/ui';
import { type ReactNode } from 'react';

import { useBreakpoint } from '~/hooks/useBreakpoint';
import { AddressLink, CheckpointSequenceLink, EpochLink } from '~/ui/InternalLink';
import { TransactionBlockCard, TransactionBlockCardSection } from '~/ui/TransactionBlockCard';

export function TransactionDetail({ label, value }: { label: string; value: ReactNode | string }) {
	return (
		<div className="flex items-center basis-1/3 first:pl-0">
			<Heading variant="heading6/medium" color="steel-darker">
				<div className='w-[230px]'>{label}</div>
			</Heading>
			<Text variant="pBody/normal" color="steel-dark">
				{value}
			</Text>
		</div>
	);
}

interface TransactionDetailsProps {
	sender?: string;
	checkpoint?: string | null;
	executedEpoch?: string;
	timestamp?: string | null;
}

export function TransactionDetailCard({
	sender,
	checkpoint,
	executedEpoch,
	timestamp,
}: TransactionDetailsProps) {
	const md = useBreakpoint('md');
	const { data: domainName } = useResolveMgoNSName(sender);

	return (
		<TransactionBlockCard size={md ? 'md' : 'sm'}>
			<TransactionBlockCardSection>
				<div className="flex flex-col gap-5 defined-bg-14 rounded-[8px] p-5">
					{checkpoint && (
						<TransactionDetail
							label="Checkpoint"
							value={
								<CheckpointSequenceLink
									sequence={checkpoint}
									label={Number(checkpoint).toLocaleString()}
								/>
							}
						/>
					)}
					{timestamp && (
						<Text variant="pBody/medium" color="steel-dark">
							<div className='flex'>
								<div className='w-[230px] md:text-heading4 text-heading6 text-steel-darker'>Timestamp</div>
								<div className='text-hero-dark'>{formatDate(Number(timestamp))}</div>
							</div>
						</Text>
					)}
					{sender && (
						<TransactionDetail
							label="Sender"
							// value={<AddressLink address={domainName ?? sender} />}
							value={<div className='border border-gray-45 defined-bg-21 rounded-[8px] px-5 py-2.5 defined-text-42'>{domainName ?? sender}</div>}
						/>
					)}
					{executedEpoch && (
						<TransactionDetail label="Epoch" value={<EpochLink epoch={executedEpoch} />} />
					)}
				</div>
			</TransactionBlockCardSection>
		</TransactionBlockCard>
	);
}
