import clsx from 'clsx';
import { lazy, Suspense , useState } from 'react';

import { AccountsCardGraph } from '~/components/AccountCardGraph';
import { Activity } from '~/components/Activity';
import { CurrentEpoch, OnTheNetwork } from '~/components/HomeMetrics';
import { PageLayout } from '~/components/Layout/PageLayout';
import { MgoTokenCard } from '~/components/MgoTokenCard';
import { TransactionsCardGraph } from '~/components/TransactionsCardGraph';
import { ErrorBoundary } from '~/components/error-boundary/ErrorBoundary';
import { TopPackagesCard } from '~/components/top-packages/TopPackagesCard';
import { TopValidatorsCard } from '~/components/top-validators-card/TopValidatorsCard';
import { useNetwork } from '~/context';
import { Card } from '~/ui/Card';
import { TabHeader } from '~/ui/Tabs';
import { Network } from '~/utils/api/DefaultRpcClient';
import Search from '~/components/search/Search';
const ValidatorMap = lazy(() => import('../../components/validator-map'));

const TRANSACTIONS_LIMIT = 25;

function Home() {
	const [network] = useNetwork();
	const [isAccount, setIsAccount] = useState(false)
	const isMgoTokenCardEnabled = network === Network.TESTNET;
	const handleClick = () => {
		setIsAccount(!isAccount)
	}
	return (
		<PageLayout
			gradient={{
				content: (
					<div
						data-testid="home-page"
						className={clsx(
							'home-page-grid-container-top defined-grid mt-asd pt-8',
							isMgoTokenCardEnabled &&  isAccount ? 'with-token2' :'with-token',
						)}
					>
						{/* 渐变 */}
						<div className='gradient-component'>
							<div className='max-width800 mt-[148px]'>
								<div className='home-search-title text-hero-dark'>Explore Mango Blockchain</div>
								<Search />
							</div>
						</div>
						<div style={{ gridArea: 'network' }} className="overflow-hidden">
							<OnTheNetwork />
						</div>
						<div style={{ gridArea: 'epoch' }}>
							<CurrentEpoch />
						</div>
						{/* {isMgoTokenCardEnabled ? (
							<div style={{ gridArea: 'token' }}>
								<MgoTokenCard />
							</div>
						) : null} */}
						{isAccount?
						<div style={{ gridArea: 'accounts' }}><AccountsCardGraph onClick={handleClick}/></div>
						 :
						 <div style={{ gridArea: 'transactions' }}><TransactionsCardGraph onClick={handleClick}/></div>}
					</div>
				),
				size: 'lg',
			}}
			content={
				<div className="home-page-grid-container-bottom defined-tabs-list">
					<div style={{ gridArea: 'activity' }}>
						<ErrorBoundary>
							<Activity initialLimit={TRANSACTIONS_LIMIT} disablePagination />
						</ErrorBoundary>
					</div>
					<div style={{ gridArea: 'packages' }}>
						{/* <TopPackagesCard /> */}
					</div>
					<div data-testid="validators-table" style={{ gridArea: 'validators' }}>
						<div className="defined-tabs-card">
							<TabHeader title="Validators">
								<ErrorBoundary>
									<TopValidatorsCard limit={10} showIcon />
								</ErrorBoundary>
							</TabHeader>
						</div>
					</div>
					<div
						style={{ gridArea: 'node-map' }}
						className="min-h-[320px] sm:min-h-[380px] lg:min-h-[460px] xl:min-h-[520px] defined-border-bc1 rounded-[10px]"
					>
						<ErrorBoundary>
							<Suspense fallback={<Card height="full" />}>
								<ValidatorMap minHeight="100%" />
							</Suspense>
						</ErrorBoundary>
					</div>
				</div>
			}
		/>
	);
}

export default Home;
