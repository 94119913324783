import { formatDate } from '@mgonetwork/core';
import { Heading, Text } from '@mgonetwork/ui';
import clsx from 'clsx';

import { getElapsedTime, useEpochProgress } from '~/pages/epochs/utils';
import { Card } from '~/ui/Card';
import { ProgressBar } from '~/ui/ProgressBar';

export interface EpochProgressProps {
	epoch?: string;
	start: number;
	end?: number;
	inProgress?: boolean;
}

export function EpochProgress({ epoch, start, end, inProgress }: EpochProgressProps) {
	const { progress, label } = useEpochProgress();

	const elapsedTime = !inProgress && start && end ? getElapsedTime(start, end) : undefined;
	// inProgress ? 'highlight' : 'default'
	return (
		<Card bg="definedBlack" spacing="lg" rounded="2xl" width="full">
			<div className="flex flex-col">
				<div className={clsx(inProgress ? 'space-y-4' : 'space-y-6')}>
					<div className="flex flex-col gap-2 p-5 defined-bg-20 rounded-[8px]">
						<Heading color="text-hero-dark" variant="heading3/semibold">
							{inProgress ?
								<div className='flex justify-between'>
									<div>Epoch</div>
									<div> <span className='text-gr-dark'>{epoch}</span> in progress </div>
								</div>
								:
								<div className='flex justify-between'>
									<div>Epoch {elapsedTime}</div>
									<div className='text-gr-dark'>{epoch} </div>
								</div>
							}
						</Heading>
						{/* {elapsedTime && (
							<Heading variant="heading6/medium" color="text-hero-dark">
								{elapsedTime}
							</Heading>
						)} */}
					</div>

					{!inProgress && end ? (
						<div className='px-5'>
							<div className='flex justify-between '>
								<Text variant="pSubtitleSmall/normal" uppercase color="steel-darker">
									Start
								</Text>
								<Text variant="pSubtitle/semibold" color="text-hero-dark">
									{formatDate(start)}
								</Text>
							</div>
							<div className='flex justify-between pt-5'>
								<Text variant="pSubtitleSmall/normal" uppercase color="steel-darker">
									End
								</Text>
								<Text variant="pSubtitle/semibold" color="text-hero-dark">
									{formatDate(end)}
								</Text>
							</div>

						</div>
					) : null}
				</div>

				{inProgress ? (<div className='p-5 mt-5 defined-epoch-bg-grey rounded-[8px]'>
					<div className="flex justify-between item-center pt-2.5">
						<ProgressBar animate progress={progress || 0} />
						<Heading variant="heading6/medium" color="text-hero-dark">
							{label}
						</Heading>
					</div>
					<div className='flex justify-between pt-7.5'>
						<Text variant="pSubtitleSmall/normal" uppercase color="steel-darker">
							Start
						</Text>
						<Text variant="pSubtitle/semibold" color="text-hero-dark">
							{formatDate(start)}
						</Text>
					</div>

				</div>
				) : null}
			</div>
		</Card>
	);
}
