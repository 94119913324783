import { Search16 } from '@mgonetwork/icons';
import { Text, Combobox, ComboboxInput, ComboboxList } from '@mgonetwork/ui';

export type SearchResult = {
	id: string;
	label: string;
	type: string;
};

export interface SearchProps {
	onChange: (value: string) => void;
	onSelectResult?: (result: SearchResult) => void;
	placeholder?: string;
	isLoading: boolean;
	options?: SearchResult[];
	queryValue: string;
}

export function Search({
	onChange,
	onSelectResult,
	placeholder,
	options = [],
	isLoading = false,
	queryValue,
}: SearchProps) {
	return (
		<Combobox value={queryValue} onValueChange={onChange}>
			<div>
				<ComboboxInput
					className="defined-text-hero-darkest placeholder:defined-text-hero-darkest hover:defined-bg-hero-darkest focus:defined-bg-hero-darkest w-full rounded border border-transparent bg-hero-darkest/5 pl-10 font-mono text-body font-medium leading-9 outline-none placeholder:text-sm"
					placeholder={placeholder} />
				<div className="defined-search-text-hero-darkest absolute right-3 ml-3 block items-center text-2xl top-1.5">
					<Search16 />
				</div>
			</div>

			<ComboboxList
				contentBg="defined-bg-20 hidden-scroll"
				isLoading={isLoading}
				onSelect={({ item }) => {
					onSelectResult?.(item);
				}}
				options={options.map((item) => ({
					item,
					value: `${item.type}/${item.id}`,
					label: item.label,
					after: (
						<Text variant="caption/medium" color="steel">
							{item.type}
						</Text>
					),
				}))}
			/>
		</Combobox>
	);
}
