import { useGetKioskContents, useGetOwnedObjects, useLocalStorage } from '@mgonetwork/core';
import { ThumbnailsOnly16, ViewList16, ViewSmallThumbnails16 } from '@mgonetwork/icons';
import { Heading, IconButton, RadioGroup, RadioGroupItem, Text } from '@mgonetwork/ui';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import "../style.scss"
import { ListView } from '~/components/OwnedObjects/ListView';
import { SmallThumbnailsView } from '~/components/OwnedObjects/SmallThumbnailsView';
import { ThumbnailsView } from '~/components/OwnedObjects/ThumbnailsView';
import { OBJECT_VIEW_MODES } from '~/components/OwnedObjects/utils';
import { useTheme } from '~/context';
import { Pagination, useCursorPagination } from '~/ui/Pagination';
import { useBreakpoint } from '~/hooks/useBreakpoint';

const PAGE_SIZES = [10, 20, 30, 40, 50];
const SHOW_PAGINATION_MAX_ITEMS = 9;
const OWNED_OBJECTS_LOCAL_STORAGE_VIEW_MODE = 'owned-objects/viewMode';
const OWNED_OBJECTS_LOCAL_STORAGE_FILTER = 'owned-objects/filter';

enum FILTER_VALUES {
	ALL = 'all',
	KIOSKS = 'kiosks',
}

const FILTER_OPTIONS = [
	{ label: 'NFTS', value: FILTER_VALUES.ALL },
	{ label: 'KIOSKS', value: FILTER_VALUES.KIOSKS },
];

const VIEW_MODES = [
	{ icon: <ViewList16 />, value: OBJECT_VIEW_MODES.LIST },
	{ icon: <ViewSmallThumbnails16 />, value: OBJECT_VIEW_MODES.SMALL_THUMBNAILS },
	{ icon: <ThumbnailsOnly16 />, value: OBJECT_VIEW_MODES.THUMBNAILS },
];

function getItemsRangeFromCurrentPage(currentPage: number, itemsPerPage: number) {
	const start = currentPage * itemsPerPage + 1;
	const end = start + itemsPerPage - 1;
	return { start, end };
}

function getShowPagination(
	filter: string | undefined,
	itemsLength: number,
	currentPage: number,
	isFetching: boolean,
) {
	if (filter === FILTER_VALUES.KIOSKS) {
		return false;
	}

	if (isFetching) {
		return true;
	}

	return currentPage !== 0 || itemsLength > SHOW_PAGINATION_MAX_ITEMS;
}

export function OwnedObjects({ id }: { id: string }) {
	const [limit, setLimit] = useState(50);
	const isMediumOrAbove = useBreakpoint('md');
	const [filter, setFilter] = useLocalStorage<string | undefined>(
		OWNED_OBJECTS_LOCAL_STORAGE_FILTER,
		undefined,
	);
	const [viewMode, setViewMode] = useLocalStorage(
		OWNED_OBJECTS_LOCAL_STORAGE_VIEW_MODE,
		OBJECT_VIEW_MODES.THUMBNAILS,
	);

	const ownedObjects = useGetOwnedObjects(
		id,
		{
			MatchNone: [{ StructType: '0x2::coin::Coin' }],
		},
		limit,
	);
	const { data: kioskData, isFetching: kioskDataFetching } = useGetKioskContents(id);

	const { data, isError, isFetching, pagination } = useCursorPagination(ownedObjects);

	const isLoading = isFetching || kioskDataFetching;

	useEffect(() => {
		if (!isLoading && !filter) {
			if (kioskData?.list?.length) {
				setFilter(FILTER_VALUES.KIOSKS);
			} else {
				setFilter(FILTER_VALUES.ALL);
			}
		}
	}, [filter, isLoading, kioskData?.list?.length, setFilter]);

	const filteredData = useMemo(
		() => (filter === FILTER_VALUES.ALL ? data?.data : kioskData?.list),
		[filter, data, kioskData],
	);

	const { start, end } = useMemo(
		() =>
			getItemsRangeFromCurrentPage(pagination.currentPage, filteredData?.length || PAGE_SIZES[0]),
		[filteredData?.length, pagination.currentPage],
	);

	const sortedDataByDisplayImages = useMemo(() => {
		if (!filteredData) {
			return [];
		}

		const hasImageUrl = [];
		const noImageUrl = [];

		for (const obj of filteredData) {
			const displayMeta = obj.data?.display?.data;

			if (displayMeta?.image_url) {
				hasImageUrl.push(obj);
			} else {
				noImageUrl.push(obj);
			}
		}

		return [...hasImageUrl, ...noImageUrl];
	}, [filteredData]);

	const showPagination = getShowPagination(
		filter,
		filteredData?.length || 0,
		pagination.currentPage,
		isFetching,
	);

	const hasAssets = sortedDataByDisplayImages.length > 0;

	if (isError) {
		return <div className="pt-2 font-sans font-semibold text-issue-dark">Failed to load NFTs</div>;
	}
	const [localTheme] = useTheme();

	return (
		<div className={clsx('flex overflow-hidden py-5 pr-5 absolute h-[820px] ', isMediumOrAbove ? 'left-405 w-[1000px]':'' , !showPagination && 'pb-2')}>
			<div className="relative flex h-full w-full flex-col gap-4 border-bc-1 p-5 rounded-[8px]">
				<div className="defined-right-panel-title flex w-full flex-col justify-between defined-bg-20 items-start gap-3 max-sm:pb-3 sm:h-14 sm:min-h-14 rounded-[8px] sm:flex-row sm:items-center px-5">
					<Heading color="hero-dark" variant="heading4/semibold">
						Assets
					</Heading>

					{hasAssets && (
						<div className="flex  flex-row-reverse justify-between sm:flex-row">
							<div className="flex items-center gap-1">
								{VIEW_MODES.map((mode) => {
									const selected = mode.value === viewMode;
									return (
										<div
											key={mode.value}
											className={clsx(
												'flex h-9 w-9 items-center justify-center',
												selected ? 'defined-text-41' : 'text-hero-dark',
											)}
										>
											<IconButton
												className={clsx(
													'defined-icon-btn flex h-full w-full items-center justify-center rounded',
												)}
												aria-label="view-filter"
												onClick={() => {
													setViewMode(mode.value);
												}}
											>
												{mode.icon}
											</IconButton>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
				{hasAssets && <div>
					<RadioGroup aria-label="View transactions by a specific filter" value={filter} onValueChange={setFilter}>
						{FILTER_OPTIONS.map((filter) => (
							<RadioGroupItem key={filter.value} value={filter.value} label={filter.label}
								isStyle
								disabled={
									(filter.value === FILTER_VALUES.KIOSKS && !kioskData?.list?.length) || isLoading} />
						))}
					</RadioGroup>
				</div>}

				{!hasAssets && !isLoading && (
					<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
						<Text variant="body/medium" color="steel-dark">
							No Assets owned
						</Text>
					</div>
				)}

				{viewMode === OBJECT_VIEW_MODES.LIST && (
					<ListView loading={isLoading} data={sortedDataByDisplayImages} />
				)}
				{viewMode === OBJECT_VIEW_MODES.SMALL_THUMBNAILS && (
					<SmallThumbnailsView loading={isLoading} data={sortedDataByDisplayImages} limit={limit} />
				)}
				{viewMode === OBJECT_VIEW_MODES.THUMBNAILS && (
					<ThumbnailsView loading={isLoading} data={sortedDataByDisplayImages} limit={limit} />
				)}
				{showPagination && (
					<div className="mt-auto flex justify-between flex-row flex-wrap gap-2 md:mb-5">
						<div className='flex items-center'>
							<div className="flex items-center mr-8">
								{!isLoading && (
									<Text variant="body/medium" color="steel">
										Showing {start} - {end}
									</Text>
								)}
							</div>
							<div className=" hidden sm:block">
								<select
									className={clsx(
										'perPage form-select rounded-md border-gray-45 px-3 py-2 pr-8 text-bodySmall font-medium leading-[1.2] text-steel-dark shadow-button',
										localTheme,
									)}
									value={limit}
									onChange={(e) => {
										setLimit(Number(e.target.value));
										pagination.onFirst();
									}}
								>
									{PAGE_SIZES.map((size) => (
										<option key={size} value={size}>
											{size} Per Page
										</option>
									))}
								</select>
							</div>
						</div>
						<Pagination {...pagination} />
					</div>
				)}
			</div>
		</div>
	);
}
