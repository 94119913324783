import { useMgoClient } from '@mgonetwork/dapp-kit';
import { Text, LoadingIndicator } from '@mgonetwork/ui';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import { CheckpointTransactionBlocks } from './CheckpointTransactionBlocks';
import { PageLayout } from '~/components/Layout/PageLayout';
import { MgoAmount } from '~/components/Table/MgoAmount';
import { useTheme } from '~/context';
import { Banner } from '~/ui/Banner';
import { DescriptionList, DescriptionItem } from '~/ui/DescriptionList';
import { EpochLink } from '~/ui/InternalLink';
import { PageHeader } from '~/ui/PageHeader';
import { TabHeader, Tabs, TabsContent, TabsList, TabsTrigger } from '~/ui/Tabs';

export default function CheckpointDetail() {
	const { id } = useParams<{ id: string }>();
	const digestOrSequenceNumber = /^\d+$/.test(id!) ? parseInt(id!, 10) : id;

	const client = useMgoClient();
	const { data, isError, isLoading } = useQuery({
		queryKey: ['checkpoints', digestOrSequenceNumber],
		queryFn: () => client.getCheckpoint({ id: String(digestOrSequenceNumber!) }),
	});
	const [localTheme] = useTheme();
	return (
		<PageLayout
			content={
				isError ? (
					<Banner variant="error" fullWidth>
						There was an issue retrieving data for checkpoint: {id}
					</Banner>
				) : isLoading ? (
					<LoadingIndicator />
				) : (
					<div className="flex flex-col space-y-12">
						<PageHeader title={data.digest} type="Checkpoint" />
						<div className=" space-y-8">
							<div className={clsx('defined-tabs-card', localTheme === 'light' ? '' : 'p-5')}>
								<Tabs size="lg" defaultValue="details">
									<div className='p-5 defined-bg-20 rounded-[8px] text-hero-dark font-semibold'>Details</div>
									{/* <TabsList>
										<TabsTrigger value="details">Details</TabsTrigger>
										<TabsTrigger value="signatures">Signatures</TabsTrigger>
									</TabsList> */}
									<TabsContent value="details">
										<div className='px-5'>
											<DescriptionList>
												<DescriptionItem color="signatures" title="Checkpoint Sequence No." labelWidth='l'>
													<Text variant="pBody/medium" color="text-hero-dark">
														{data.sequenceNumber}
													</Text>
												</DescriptionItem>
												<DescriptionItem color="signatures" title="Epoch" labelWidth='l'>
													<EpochLink epoch={data.epoch} />
												</DescriptionItem>
												<DescriptionItem color="signatures" title="Checkpoint Timestamp" labelWidth='l'>
													<Text variant="pBody/medium" color="text-hero-dark">
														{data.timestampMs
															? new Date(Number(data.timestampMs)).toLocaleString(undefined, {
																month: 'short',
																day: 'numeric',
																year: 'numeric',
																hour: 'numeric',
																minute: '2-digit',
																second: '2-digit',
																hour12: false,
																timeZone: 'UTC',
																timeZoneName: 'short',
															})
															: '--'}
													</Text>
												</DescriptionItem>
											</DescriptionList>
										</div>
									</TabsContent>
									<TabsContent value="details">
										<div className='p-5 defined-bg-20 rounded-[8px] text-hero-dark font-semibold mt-10 mb-5'>Signatures</div>
										<Tabs defaultValue="aggregated">
											<div className='text-hero-dark px-5'>Aggregated Validator Signature</div>
											{/* <TabsList>
												<TabsTrigger value="aggregated">Aggregated Validator Signature</TabsTrigger>
											</TabsList> */}
											<TabsContent value="aggregated">
												<div className='px-5'>
													<DescriptionList>
														<DescriptionItem
															color="signatures"
															key={data.validatorSignature}
															title="Signature"
															labelWidth='l'
														>
															<Text variant="pBody/medium" color="text-hero-dark">
																{data.validatorSignature}
															</Text>
														</DescriptionItem>
													</DescriptionList>
												</div>
											</TabsContent>
										</Tabs>
									</TabsContent>

								</Tabs>
							</div>
							<div className={clsx('defined-tabs-card', localTheme === 'light' ? '' : 'p-5')}>
								<TabHeader title="Gas & Storage Fees" isList>
									<div className='px-5'>
										<DescriptionList>
											<DescriptionItem title="Computation Fee" color="signatures" labelWidth='l'>
												<MgoAmount full amount={data.epochRollingGasCostSummary.computationCost} />
											</DescriptionItem>
											<DescriptionItem title="Storage Fee" color="signatures" labelWidth='l'>
												<MgoAmount full amount={data.epochRollingGasCostSummary.storageCost} />
											</DescriptionItem>
											<DescriptionItem title="Storage Rebate" color="signatures" labelWidth='l'>
												<MgoAmount full amount={data.epochRollingGasCostSummary.storageRebate} />
											</DescriptionItem>
										</DescriptionList>
									</div>
								</TabHeader>
							</div>
							<div className='pt-5'>
								<TabHeader title="Checkpoint Transaction Blocks">
									<div className="mt-4">
										<CheckpointTransactionBlocks id={data.sequenceNumber} />
									</div>
								</TabHeader>
							</div>
						</div>
					</div>
				)
			}
		/>
	);
}
