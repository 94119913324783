import { useFormatCoin } from '@mgonetwork/core';
import { ArrowShowAndHideRight12, Warning16 } from '@mgonetwork/icons';
import { MGO_TYPE_ARG } from '@mgonetwork/mango.js/utils';
import { Text } from '@mgonetwork/ui';
import * as Collapsible from '@radix-ui/react-collapsible';
import clsx from 'clsx';
import { useState } from 'react';

import { type CoinBalanceVerified } from '.';
import { CoinIcon } from './CoinIcon';
import CoinsPanel from './OwnedCoinsPanel';
import { Banner } from '~/ui/Banner';
import { Tooltip } from '~/ui/Tooltip';
import { ampli } from '~/utils/analytics/ampli';

type OwnedCoinViewProps = {
	coin: CoinBalanceVerified;
	id: string;
};

export default function OwnedCoinView({ coin, id }: OwnedCoinViewProps) {
	const isMgoCoin = coin.coinType === MGO_TYPE_ARG;
	const [open, setOpen] = useState(isMgoCoin);
	const [formattedTotalBalance, symbol] = useFormatCoin(coin.totalBalance, coin.coinType);
	return (
		<Collapsible.Root open={open} onOpenChange={setOpen}>
			<Collapsible.Trigger
				data-testid="ownedcoinlabel"
				className={clsx(
					'hover:my-defined-bg-hero-darkest mt-1 flex w-full items-center rounded-lg bg-opacity-5 p-2 text-left',
					open ? 'my-defined-bg-hero-darkest rounded-b-none pt-3' : 'rounded-b-lg',
				)}
			>
				<div className="flex w-[45%] items-center gap-1 truncate">
					{/* <div className={clsx('defined-ArrowShowAndHideRight12','rotate-0', open && ' rotate-90 transform')}></div> */}
					<ArrowShowAndHideRight12
						width={16}
						className={clsx('rotate-0 text-gray-60', open && ' rotate-90 transform')}
					/>

					<div className="flex items-center gap-3 truncate">
						<div className="w-6">
							<CoinIcon coinType={coin.coinType} size="sm" />
						</div>
						<Text color="text-hero-dark" variant="body/medium" truncate>
							{symbol}
						</Text>
					</div>

					{!coin.isRecognized && (
						<Tooltip
							tip="This coin has not been recognized by Mango Foundation."
							onOpen={() =>
								ampli.activatedTooltip({
									tooltipLabel: 'unrecognizedCoinWarning',
								})
							}
						>
							<Banner variant="warning" icon={null} border spacing="sm">
								<Warning16 />
							</Banner>
						</Tooltip>
					)}
				</div>

				<div className="flex w-[25%] pl-2">
					<Text color={coin.isRecognized ? 'text-hero-dark' : 'gray-60'} variant="body/medium">
						{coin.coinObjectCount}
					</Text>
				</div>

				<div className="flex w-[30%] items-center gap-1 truncate pl-1">
					<Text
						color={coin.isRecognized ? 'text-hero-dark' : 'gray-60'}
						variant="bodySmall/medium"
						truncate
					>
						{formattedTotalBalance}
					</Text>
					<Text color="steel-darker" variant="subtitleSmallExtra/normal" truncate>
						{symbol}
					</Text>
				</div>
			</Collapsible.Trigger>

			<Collapsible.Content>
				<div className="my-defined-bg-hero-darkest flex flex-col gap-1 rounded-bl-lg rounded-br-lg p-3">
					<CoinsPanel id={id} coinType={coin.coinType} />
				</div>
			</Collapsible.Content>
		</Collapsible.Root>
	);
}
