// import * as Sentry from '@sentry/react';
import { createContext, useContext, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom';

import { Network, Theme } from './utils/api/DefaultRpcClient';
// import { growthbook } from './utils/growthbook';
import { queryClient } from './utils/queryClient';

export const DEFAULT_NETWORK =
	import.meta.env.VITE_NETWORK || (import.meta.env.DEV ? Network.LOCAL : Network.TESTNET);
export const DEFAULT_THEME = Theme.Light;
export const NetworkContext = createContext<
	[Network | string, (network: Network | string) => void]
>(['', () => null]);

export function useNetworkContext() {
	return useContext(NetworkContext);
}

export function useNetwork(): [string, (network: Network | string) => void] {
	const [searchParams, setSearchParams] = useSearchParams();

	const network = useMemo(() => {
		const networkParam = searchParams.get('network');

		if (networkParam && (Object.values(Network) as string[]).includes(networkParam.toUpperCase())) {
			return networkParam.toUpperCase();
		}

		return networkParam ?? DEFAULT_NETWORK;
	}, [searchParams]);

	const setNetwork = (network: Network | string) => {
		// When resetting the network, we reset the query client at the same time:
		queryClient.cancelQueries();
		queryClient.clear();

		setSearchParams({ ...searchParams, network: network.toLowerCase() });
	};

	// useLayoutEffect(() => {
	// 	growthbook.setAttributes({
	// 		network,
	// 		environment: import.meta.env.VITE_VERCEL_ENV,
	// 	});
	//
	// 	Sentry.setContext('network', {
	// 		network,
	// 	});
	// }, [network]);

	return [network, setNetwork];
}

//  设置主题颜色

export const ThemeContext = createContext<[Theme | string, (theme: Theme | string) => void]>([
	'',
	() => null,
]);

export function useThemeContext() {
	return useContext(ThemeContext);
}

export function useTheme(): [string, (theme: Theme | string) => void] {
	const [contextTheme, setContextTheme] = useThemeContext();

	const localTheme = useMemo(() => contextTheme ?? DEFAULT_THEME, [contextTheme]);
	const setTheme = (theme: Theme | string) => {
		setContextTheme(theme);
	};

	// useLayoutEffect(() => {
	// 	growthbook.setAttributes({
	// 		localTheme,
	// 		environment: import.meta.env.VITE_VERCEL_ENV,
	// 	});
	//
	// 	Sentry.setContext('theme', {
	// 		localTheme,
	// 	});
	// }, [localTheme]);

	return [localTheme, setTheme];
}
