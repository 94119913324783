import { formatAmountParts } from '@mgonetwork/core';

import { Stats, type StatsProps } from '~/ui/Stats';

// Simple wrapper around stats to avoid text wrapping:
export function StatsWrapper(props: StatsProps) {
	const { textRight } = props;
	return (
		<div className="flex-shrink-0">
			<Stats {...props} textRight={textRight} />
		</div>
	);
}

export function FormattedStatsAmount({
	amount,
	textRight,
	...props
}: Omit<StatsProps, 'children'> & {
	amount?: string | number | bigint;
	textRight?: boolean
}) {
	const [formattedAmount, postfix] = formatAmountParts(amount);

	return (
		<StatsWrapper {...props} postfix={postfix} textRight={textRight}>
			{formattedAmount}
		</StatsWrapper>
	);
}
