import { SentryHttpTransport } from '@mgonetwork/core';
import { MgoClient, MgoHTTPTransport, getFullnodeUrl } from '@mgonetwork/mango.js/client';

export enum Network {
	// DEVNET = 'DEVNET',
	TESTNET = 'TESTNET',
	LOCAL = 'LOCAL',
}

export enum Theme {
	Light = 'light',
	Dark = 'dark',
}

export const NetworkConfigs: Record<Network, { url: string }> = {
	// [Network.DEVNET]: { url: import.meta.env.VITE_DEVNET_URL },
	[Network.TESTNET]: { url: import.meta.env.VITE_TESTNET_URL },
	[Network.LOCAL]: { url: getFullnodeUrl('localnet') },
};

const defaultClientMap: Map<Network | string, MgoClient> = new Map();

// NOTE: This class should not be used directly in React components, prefer to use the useMgoClient() hook instead
export const createMgoClient = (network: Network | string) => {
	const existingClient = defaultClientMap.get(network);
	if (existingClient) return existingClient;

	const networkUrl = network in Network ? NetworkConfigs[network as Network].url : network;

	const client = new MgoClient({
		transport:
			network in Network && network === Network.TESTNET
				? new SentryHttpTransport(networkUrl)
				: new MgoHTTPTransport({ url: networkUrl }),
	});
	defaultClientMap.set(network, client);
	return client;
};
