import { Heading } from '@mgonetwork/ui';
import { type ReactNode } from 'react';

import { Card } from '~/ui/Card';

interface EpochStatsProps {
	label: string;
	children: ReactNode;
}

export function EpochStats({ label, children }: EpochStatsProps) {
	return (
		<Card spacing="lg" bg="definedBlack" rounded="2xl">
			<div className="flex flex-col gap-6">
				{label && (
					<Heading color="steel-darker" variant="heading4/semibold">
						<div className='defined-epoch-bg-grey p-5 rounded-[8px]'>{label}</div>
					</Heading>
				)}
				<div className="grid grid-cols-1 gap-8 px-5">{children}</div>{/*  */}
			</div>
		</Card>
	);
}
