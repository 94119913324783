import { useFeatureValue } from '@growthbook/growthbook-react';
import { MGO_FRAMEWORK_ADDRESS, MGO_SYSTEM_ADDRESS, MGO_USDT_ADDRESS } from '@mgonetwork/mango.js/utils';

import { useNetwork } from '~/context';
import { Network } from '~/utils/api/DefaultRpcClient';

const DEFAULT_RECOGNIZED_PACKAGES = [MGO_FRAMEWORK_ADDRESS, MGO_SYSTEM_ADDRESS, MGO_USDT_ADDRESS];

export function useRecognizedPackages() {
	const [network] = useNetwork();

	const recognizedPackages = useFeatureValue('recognized-packages', DEFAULT_RECOGNIZED_PACKAGES);

	// Our recognized package list is currently only available on mainnet
	return network === Network.TESTNET ? recognizedPackages : DEFAULT_RECOGNIZED_PACKAGES;
}
