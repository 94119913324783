import { toB64 } from '@mgonetwork/bcs';
import { keccak_256 } from '@noble/hashes/sha3';
import { bech32 } from 'bech32';

import { bcs } from '../bcs/index.js';
import { IntentScope, messageWithIntent } from './intent.js';
import type { PublicKey } from './publickey.js';
import type { SignatureScheme } from './signature-scheme.js';
import { SIGNATURE_FLAG_TO_SCHEME, SIGNATURE_SCHEME_TO_FLAG } from './signature-scheme.js';
import type { SerializedSignature } from './signature.js';
import { toSerializedSignature } from './signature.js';

export const PRIVATE_KEY_SIZE = 32;
export const LEGACY_PRIVATE_KEY_SIZE = 64;
export const MGO_PRIVATE_KEY_PREFIX = 'mgoprivkey';

export type ParsedKeypair = {
	schema: SignatureScheme;
	secretKey: Uint8Array;
};

/** @deprecated use string instead. See {@link Keypair.getSecretKey} */
export type ExportedKeypair = {
	schema: SignatureScheme;
	privateKey: string;
};

export interface SignatureWithBytes {
	bytes: string;
	signature: SerializedSignature;
}

/**
 * TODO: Document
 */
export abstract class BaseSigner {
	abstract sign(bytes: Uint8Array): Promise<Uint8Array>;
	/**
	 * Sign messages with a specific intent. By combining the message bytes with the intent before hashing and signing,
	 * it ensures that a signed message is tied to a specific purpose and domain separator is provided
	 */
	async signWithIntent(bytes: Uint8Array, intent: IntentScope): Promise<SignatureWithBytes> {
		const intentMessage = messageWithIntent(intent, bytes);
		const digest = keccak_256(intentMessage);

		const signature = toSerializedSignature({
			signature: await this.sign(digest),
			signatureScheme: this.getKeyScheme(),
			publicKey: this.getPublicKey(),
		});

		return {
			signature,
			bytes: toB64(bytes),
		};
	}
	/**
	 * Signs provided transaction block by calling `signWithIntent()` with a `TransactionData` provided as intent scope
	 */
	async signTransactionBlock(bytes: Uint8Array) {
		return this.signWithIntent(bytes, IntentScope.TransactionData);
	}
	/**
	 * Signs provided personal message by calling `signWithIntent()` with a `PersonalMessage` provided as intent scope
	 */
	async signPersonalMessage(bytes: Uint8Array) {
		return this.signWithIntent(
			bcs.ser(['vector', 'u8'], bytes).toBytes(),
			IntentScope.PersonalMessage,
		);
	}

	/**
	 * @deprecated use `signPersonalMessage` instead
	 */
	async signMessage(bytes: Uint8Array) {
		return this.signPersonalMessage(bytes);
	}

	toMgoAddress(): string {
		return this.getPublicKey().toMgoAddress();
	}

	/**
	 * Return the signature for the data.
	 * Prefer the async version {@link sign}, as this method will be deprecated in a future release.
	 */
	abstract signData(data: Uint8Array): Uint8Array;

	/**
	 * Get the key scheme of the keypair: Secp256k1 or ED25519
	 */
	abstract getKeyScheme(): SignatureScheme;

	/**
	 * The public key for this keypair
	 */
	abstract getPublicKey(): PublicKey;
}

/**
 * TODO: Document
 */
export abstract class Keypair extends BaseSigner {
	/**
	 * This returns the Bech32 secret key string for this keypair.
	 */
	abstract getSecretKey(): string;

	abstract export(): ExportedKeypair;
}

/**
 * This returns an ParsedKeypair object based by validating the
 * 33-byte Bech32 encoded string starting with `mgoprivkey`, and
 * parse out the signature scheme and the private key in bytes.
 */
export function decodeMgoPrivateKey(value: string): ParsedKeypair {
	const { prefix, words } = bech32.decode(value);
	if (prefix !== MGO_PRIVATE_KEY_PREFIX) {
		throw new Error('invalid private key prefix');
	}
	const extendedSecretKey = new Uint8Array(bech32.fromWords(words));
	const secretKey = extendedSecretKey.slice(1);
	const signatureScheme =
		SIGNATURE_FLAG_TO_SCHEME[extendedSecretKey[0] as keyof typeof SIGNATURE_FLAG_TO_SCHEME];
	return {
		schema: signatureScheme,
		secretKey: secretKey,
	};
}

/**
 * This returns a Bech32 encoded string starting with `mgoprivkey`,
 * encoding 33-byte `flag || bytes` for the given the 32-byte private
 * key and its signature scheme.
 */
export function encodeMgoPrivateKey(bytes: Uint8Array, scheme: SignatureScheme): string {
	if (bytes.length !== PRIVATE_KEY_SIZE) {
		throw new Error('Invalid bytes length');
	}
	const flag = SIGNATURE_SCHEME_TO_FLAG[scheme];
	const privKeyBytes = new Uint8Array(bytes.length + 1);
	privKeyBytes.set([flag]);
	privKeyBytes.set(bytes, 1);
	return bech32.encode(MGO_PRIVATE_KEY_PREFIX, bech32.toWords(privKeyBytes));
}