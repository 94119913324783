import { ChevronRight12 } from '@mgonetwork/icons';
import { type MgoEvent } from '@mgonetwork/mango.js/client';
import { parseStructTag, formatAddress } from '@mgonetwork/mango.js/utils';
import { Text } from '@mgonetwork/ui';
import * as Collapsible from '@radix-ui/react-collapsible';
import clsx from 'clsx';
import { useState } from 'react';

import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { CopyToClipboard } from '~/ui/CopyToClipboard';
import { DescriptionItem } from '~/ui/DescriptionList';
import { Divider } from '~/ui/Divider';
import { ObjectLink } from '~/ui/InternalLink';
import { useTheme } from '~/context';

function Event({ event, divider }: { event: MgoEvent; divider: boolean }) {
	const [localTheme] = useTheme();
	const [open, setOpen] = useState(false);
	const { address, module, name } = parseStructTag(event.type);
	const objectLinkLabel = [formatAddress(address), module, name].join('::');
	const transactionModule = event.transactionModule;
	const codeEvent = JSON.stringify(event, null, 2);
	return (
		<div>
			<div className="flex flex-col gap-8 border border-gray-45 p-5 rounded-[8px] pb-20">
				<DescriptionItem title="Type" align="start" color="signatures" labelWidth="sm">
					<Text variant="pBody/medium" color="steel-darker">
						{objectLinkLabel}
					</Text>
				</DescriptionItem>

				<DescriptionItem title="Event Emitter" color="signatures" align="start" labelWidth="sm">
					<div className="flex items-center gap-1">
						<ObjectLink
							className="defined-signatures-link-color break-all font-mono text-body font-medium defined-text-42  hover:text-hero-darkest"
							objectId={event.packageId}
							queryStrings={{ module: transactionModule }}
							label={`${formatAddress(event.packageId)}::${transactionModule}`}
						/>
						<CopyToClipboard color="text42" copyText={event.packageId} />
					</div>
				</DescriptionItem>

				<Collapsible.Root open={open} onOpenChange={setOpen} asChild>
					<>
						<Collapsible.Trigger className="flex cursor-pointer items-center gap-1.5">
							<Text variant="body/semibold" color="steel-darker">
								{open ? 'Hide' : 'View'} Event Data
							</Text>

							<ChevronRight12 className={clsx('h-3 w-3 text-steel-darker', open && 'rotate-90')} />
						</Collapsible.Trigger>

						<Collapsible.Content
							className={clsx(
								'rounded-lg border border-transparent  p-5',
								localTheme === 'light' ? 'bg-blue' : 'defined-bg-14',
							)}
						>
							<SyntaxHighlighter code={codeEvent} language="json" />
						</Collapsible.Content>
					</>
				</Collapsible.Root>
			</div>

			{divider && (
				<div className="my-6">
					<Divider />
				</div>
			)}
		</div>
	);
}

interface EventsProps {
	events: MgoEvent[];
}

export function Events({ events }: EventsProps) {
	return (
		<div>
			{events.map((event, index) => (
				<Event key={event.type} event={event} divider={index !== events.length - 1} />
			))}
		</div>
	);
}
