import { Heading, Text } from '@mgonetwork/ui';
import clsx from 'clsx';

import { ReactComponent as InfoSvg } from './icons/info_10x10.svg';
import { Tooltip } from '~/ui/Tooltip';
import { ampli } from '~/utils/analytics/ampli';

import type { ReactNode } from 'react';

export type StatsProps = {
	size?: 'sm' | 'md';
	label: string;
	boxRight?: boolean;
	children?: ReactNode;
	tooltip?: string;
	unavailable?: boolean;
	postfix?: ReactNode;
	textRight?: boolean;
	start?:boolean;
	orientation?: 'horizontal' | 'vertical';
	color?: 'steel-darker' | 'hero';
};

export function Stats({
	label,
	start,
	children,
	tooltip,
	unavailable,
	postfix,
	size = 'md',
	orientation = 'vertical',
	color = 'steel-darker',
	textRight,
	boxRight
}: StatsProps) {
	return (
		<div
			className={clsx(
				`flex max-w-full flex-nowrap justify-between flex-row-reverse gap-1.5`,
				orientation === 'horizontal' ? '' : 'flex-col',
			)}
		>
			<div className={`flex items-baseline gap-0.5 text-gr-dark ${textRight ? "justify-end":""}`}>
				<Heading variant={size === 'md' ? 'heading6/normal' : 'heading6/normal'} color={`${unavailable ? 'text-hero-dark' : 'hero-dark'}`}>
					{boxRight ? <div className='min-w-[300px] max-w-[300px]'>{unavailable || children == null ? '--' : children}</div> : (unavailable || children == null ? '--' : children) }
				</Heading>

				{postfix && (
					<Heading variant={size === 'md' ? 'heading6/normal' : 'heading6/normal'} color="hero-dark">
						{postfix} 
					</Heading>
				)}
			</div>
			<div className={`flex items-center justify-start gap-1 overflow-hidden text-caption ${textRight ? "justify-end":""}`}>
				<Text variant="caption/semibold" color={color} truncate>
					{label}
				</Text>
				{tooltip && (
					<Tooltip
						tip={unavailable ? 'Coming soon' : tooltip}
						onOpen={() => {
							ampli.activatedTooltip({ tooltipLabel: label });
						}}
					>
						<InfoSvg />
					</Tooltip>
				)}
			</div>
			
		</div>
	);
}
