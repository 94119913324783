import { ThumbUpFill24, ThumbDownFill24 } from '@mgonetwork/icons';
import clsx from 'clsx';

export function StatusIcon({ success }: { success: boolean }) {
	const Icon = success ? ThumbUpFill24 : ThumbDownFill24;
	return (
		<div
			className={clsx(
				'flex items-center justify-center rounded-full',
				success ? 'bg-success' : 'bg-issue',
			)}
		>
			<Icon fill="currentColor" className="text-white sm:text-2xl" />
		</div>
	);
}
