import { type MgoValidatorSummary } from '@mgonetwork/mango.js/client';
import { Heading } from '@mgonetwork/ui';

import { DelegationAmount } from './DelegationAmount';
import { Card } from '~/ui/Card';
import { Stats } from '~/ui/Stats';

type StatsCardProps = {
	validatorData: MgoValidatorSummary;
	epoch: number | string;
	epochRewards: number | null;
	apy: number | string | null;
	tallyingScore: string | null;
};

export function ValidatorStats({
	validatorData,
	epochRewards,
	apy,
	tallyingScore,
}: StatsCardProps) {
	// TODO: add missing fields
	// const numberOfDelegators = 0;
	//  const networkStakingParticipation = 0;
	//  const votedLastRound =  0;
	//  const lastNarwhalRound = 0;

	const totalStake = Number(validatorData.stakingPoolMgoBalance);
	const commission = Number(validatorData.commissionRate) / 100;
	const rewardsPoolBalance = Number(validatorData.rewardsPool);

	return (
		<div className="flex flex-col items-stretch gap-5 border-bc-1 p-5 rounded-[8px]">
			<div className="padding0 flex-grow">
				<Card spacing="none" height="full" bg={null}>
					<div className="flex basis-full flex-col gap-10 md:basis-1/3 ">
						<Heading as="div" variant="heading4/semibold" color="hero-dark">
							<div className='defined-bg-22 p-5 rounded-[4px] mt-1'>MGO Staked on Validator</div>
						</Heading>
						<div className="flex flex-col gap-8 px-5">
							<div className='max-w-[800px]'>
								<Stats
									start
									label="Staking APY"
									orientation="horizontal"
									tooltip="This is the Annualized Percentage Yield of the a specific validator’s past operations. Note there is no guarantee this APY will be true in the future."
									unavailable={apy === null}
								>
									<div className='min-w-[300px] max-w-[300px] text-hero-dark text-[16px]'>{apy}%</div>
								</Stats>
							</div>
							<div className='flex flex-col gap-8'>
								<div className='max-w-[800px]'>
									<Stats
										orientation="horizontal"
										label="Total MGO Staked"
										tooltip="The total MGO staked on the network by validators and delegators to validate the network and earn rewards."
										unavailable={totalStake <= 0}
									>
										<div className='min-w-[300px] max-w-[300px] text-hero-dark text-[16px]'><DelegationAmount amount={totalStake} isStats /></div>
									</Stats>
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-8 px-5">
							<div className='max-w-[800px]'>
								<Stats orientation="horizontal" label="Commission" tooltip="Fee charged by the validator for staking services">
									<Heading as="h3" variant="heading2/normal" color="white">
										<div className='min-w-[300px] max-w-[300px] text-hero-dark text-[16px]'>{commission}%</div>
									</Heading>
								</Stats>
							</div>
							<div className='max-w-[800px]'>
								<Stats orientation="horizontal" label="Delegators" tooltip="The number of active delegators" unavailable boxRight />
							</div>
						</div>
					</div>
				</Card>
			</div>
			<div className="padding0 flex-grow">
				<Card spacing="none" height="full" bg={null}>
					<div className="flex basis-full flex-col items-stretch gap-10 md:basis-80">
						<Heading as="div" variant="heading4/semibold" color="hero-dark">
							<div className='defined-bg-22 p-5 rounded-[4px] mt-6'>Validator Staking Rewards</div>
						</Heading>
						<div className="flex flex-col gap-8 px-5">
							<div className='max-w-[800px]'>
								<Stats
									label="Last Epoch Rewards"
									orientation="horizontal"
									tooltip="The stake rewards collected during the last epoch."
									unavailable={epochRewards === null}
								>
									<div className='min-w-[300px] max-w-[300px] text-hero-dark text-[16px]'><DelegationAmount amount={typeof epochRewards === 'number' ? epochRewards : 0n} isStats /></div>
								</Stats>
							</div>

							<div className='max-w-[800px]'>
							<Stats
								label="Reward Pool"
								orientation="horizontal"
								tooltip="Amount currently in this validator’s reward pool"
								unavailable={Number(rewardsPoolBalance) <= 0}
							>
								<div className='min-w-[300px] max-w-[300px] text-hero-dark text-[16px]'><DelegationAmount amount={rewardsPoolBalance} isStats /></div>
							</Stats>
							</div>
						</div>
					</div>
				</Card>
			</div>

			<div className="padding0 flex-grow">
				<Card spacing="none" height="full" bg={null}>
					<div className="flex max-w-full flex-col gap-10">
						<Heading as="div" variant="heading4/semibold" color="hero-dark">
						<div className='defined-bg-22 p-5 rounded-[4px] mt-6'>Network Participation</div>
						</Heading>
						<div className="flex flex-col gap-8 px-5">
							<div className="flex flex-col gap-8 ">
								<div className='max-w-[800px]'>
								<Stats
									label="Checkpoint Participation"
									orientation="horizontal"
									tooltip="The percentage of checkpoints certified thus far by this validator."
									unavailable
									boxRight
								/>
								</div>

								<div className='max-w-[800px]'>
								<Stats
									label="Voted Last Round"
									orientation="horizontal"
									tooltip="Did this validator vote in the latest round."
									unavailable
									boxRight
								/>
								</div>
							</div>
							<div className="flex flex-col gap-8">
								<div className='max-w-[800px]'>
								<Stats
									label="Tallying Score"
									orientation="horizontal"
									tooltip="A score generated by validators to evaluate each other’s performance throughout Mgo’s regular operations."
									unavailable={!tallyingScore}
								>
									<div className='min-w-[300px] max-w-[300px] text-hero-dark text-[16px]'>{tallyingScore}</div>
								</Stats>
								</div>
								<div className='max-w-[800px]'>
								<Stats
									label="Last Narwhal Round"
									orientation="horizontal"
									tooltip="Latest Narwhal round for this epoch."
									unavailable
									boxRight
								/>
								</div>
								<div className='max-w-[800px]'>
								<Stats
									label="Proposed Next Epoch Gas Price"
									orientation="horizontal"
									tooltip="This validator's gas price quote for the next epoch."
								>
									<div className='min-w-[300px] max-w-[300px] text-hero-dark text-[16px]'><DelegationAmount amount={validatorData.nextEpochGasPrice} isStats inMIST /></div>
								</Stats>
								</div>
							</div>
						</div>
					</div>
				</Card>
			</div>
		</div>
	);
}
